<template>
  <el-dialog title="创建子用户" :visible.sync="dialogFormVisible" width="800px">
    <el-form
      ref="form"
      :model="form"
      class="commonForm col12"
      :rules="ruleForm"
      label-position="top"
    >
      <el-form-item
        multi
        label="企业"
        style="width: 49%"
        prop="enterpriseCode"
        v-if="role !== 'enterprise_agent'"
      >
        <el-select
          v-model="form.enterpriseCode"
          placeholder="请选择企业"
          style="width: 100%"
          filterable
          @change="enterpriseChange"
        >
          <el-option
            v-for="(item, index) of eList"
            :key="index"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        multi
        label="角色（帐户创建后角色不可修改）"
        style="width: 49%"
        prop="roleName"
        v-if="role !== 'enterprise_agent'"
      >
        <el-select
          v-model="form.roleName"
          disabled
          placeholder="用户角色"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) of roleOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" autocomplete="off"> </el-input>
      </el-form-item>
      <el-form-item label="手机号(用于找回密码)" prop="phone">
        <el-input v-model="form.phone" type="text" autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item
        :label="'用户名(登录名称，例如：xjj@' + namespace + ')'"
        prop="username"
      >
        <el-input v-model="form.username" autocomplete="off">
          <template slot="append">@{{ namespace }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" type="password" autocomplete="off">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog('form')">取 消</el-button>
      <el-button type="primary" @click="submit('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { phoneReg } from '../../../lib/global';
import { api } from '../../../api';
export const enterpriseList = api()('enterprise.list.json');
export const userAddSubuser = api()('user.add.subuser.json');
export default {
  props: {
    dialogFormVisible: Boolean,
    role: String
  },

  data() {
    return {
      eList: [],
      form: {
        enterpriseCode: '',
        name: '',
        phone: '',
        username: '',
        password: '',
        roleName: ''
      },
      ruleForm: {
        enterpriseCode: [
          { required: true, message: '请选择企业', trigger: 'change' }
        ],
        roleName: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      roleOptions: [
        {
          label: '放薪管家财务人员',
          value: 'enterprise_finance'
        },
        {
          label: '放薪管家车队长',
          value: 'enterprise_carLeader'
        },
        {
          label: '一级代理',
          value: 'agent_root'
        },
        {
          label: '二级代理',
          value: 'enterprise_agent'
        },
        {
          label: '代理商成员',
          value: 'enterprise_agent_user'
        }
      ],
      namespace: 'namespace'
    };
  },

  created() {
    if (this.role === 'platform') {
      this.form.roleName = 'agent_root';
    }
    if (this.role === 'agent_root') {
      this.form.roleName = 'enterprise_agent';
    }
    if (this.role === 'enterprise_agent') {
      this.namespace = window.localStorage.getItem('username').split('@')[1];
    }
    this.getEList();
  },
  methods: {
    async getEList() {
      const res = await enterpriseList({
        pageNumber: 1,
        pageSize: 10000,
        $ignoreRepeat: true
      });
      this.eList = res.list;
    },
    enterpriseChange() {
      this.namespace = this.eList.find(
        it => it.code === this.form.enterpriseCode
      ).namespace;

      if (this.role === 'agent_root') {
        if (this.form.enterpriseCode === localStorage.getItem('enterprise')) {
          this.form.roleName = 'enterprise_agent_user';
        } else {
          this.form.roleName = 'enterprise_agent';
        }
      }
    },
    closeDialog(form) {
      this.$refs[form].resetFields();
      this.$emit('update:dialogFormVisible', false);
      this.$emit('close');
    },
    submit(form) {
      this.$refs[form].validate(async isValid => {
        if (isValid) {
          if (!phoneReg.test(this.form.phone)) {
            return this.$message.error('请输入正确的手机号码！');
          }
          let _params = {
            ...this.form,
            username: this.form.username + '@' + this.namespace
          };
          if (this.role === 'enterprise_agent') {
            _params = {
              name: this.form.name,
              phone: this.form.phone,
              username: this.form.username + '@' + this.namespace,
              password: this.form.password,
              roleName: 'enterprise_agent_user',
              enterpriseCode: window.localStorage.getItem('enterprise')
            };
          }
          await userAddSubuser(_params);
          this.$message.success('创建成功！');
          this.closeDialog(form);
        }
      });
    }
  }
};
</script>

<style></style>
